#body {
  margin: 0 auto;
  width: 100%;
  height: 100%;
}

.App-header {
  text-align: center;
  display: flex;
  margin: 0 auto;
}

.App .banner-wrapper{
  height: 100%;
  width: 30vw;
  align-self: center;
  margin: auto;
}


/* NAVIGATION */
#navbar{
  min-height: 5vh;
  width: 100%;
  position: fixed;
  top: 0;
  z-index: 10;
  display: flex;
  justify-content: flex-end;
  text-decoration: none;
  background: #000;
}
#navbar ul{
  display: flex;
  padding: 10px 5px;
  justify-content: space-evenly;
  width: 35%;
}
#navbar ul a{
  color: #fff;
  text-decoration: none;
  list-style: none;
  font-size: .8rem;
  font-family: 'Roboto', sans-serif;
}
#navbar ul a:hover{
  color: red;
}



/* --- MEDIA RESPONSIVEBESS --- */
@media only screen and (max-width: 1305px){
 
  /* Project Page */  
  .pj-container, .pj-big{
    width: 70vw !important;
  }
}

@media only screen and (max-width: 1185px){
   .tech-stack div{
    width: 35vw !important;
  }
  
 /* Project Page */  
 .pj-container, .pj-big{
    width: 78vw !important;
  }
  .pj-big h2{
    font-size: 1.5rem !important;
  }  
  .pj-big p{
    font-size: 1rem !important;
  }
  .proj-card{
    margin: 0 auto;
    width: 35vw !important;
  }
  .pj-container .top img{
    width: 35vw !important;
  }
}

@media only screen and (max-width: 1024px){
  /* Banner 1 About */
  .pic-title{width: 40% !important;}
  .skills-container{width: 40% !important}
  .skills-container svg{font-size:1.25rem !important}

  /* Banner 2 - Tech Skills  */
  .tech-stack div{
    width: 40vw !important;
  }
  .tech-stack li p{
    font-size: 1rem !important; 
  }

  /* Banner 3 - Video Introduction */
  .video-container .question-container{
    width: 50%
  }
  .video-container video{
    height: 60vh;
  }

   /*  Carousel Banner */
   .carousel-banner {
    margin: 10vh auto !important;
  }
}

/* IPAD */
@media only screen and (max-width: 850px) {
  /* navbar */
  #navbar {
    justify-content: center;
    width: 100vw;
  }
  #navbar ul{
    width: 100%;
    padding: 10px 5px;
    margin: auto;
    align-items: center;
  }
  #navbar ul a{
    margin: 2px;
  }

  /* Banner 0 - Title */
  .home-banner h1{width: 80%}
  .home-banner h3{font-size: 2rem}
  

  /* Banner 2 - About */
  .banner-2 {
    min-height: 100vh !important;
    height: 100% !important;
  }
    
  /* Banner 2 - Tech Skills */
  .tech-stack div{
    width: 45vw !important;
  }

  /* Banner 3 - Projects????????? */
  .banner-3{
    background: #fff !important
  }
  .banner-3 .pj-container{
    margin: 0 auto;
    display: flex;
    flex-wrap: wrap;
    justify-content: space-between;
    height: 100%; 
    width: 100vw;
    transform: none !important;
  }
  .banner-3 h2{
    font-size: 1.25rem;
    margin-bottom: 0
  }

  /*  Banner 3 - Video Intro */
  .video-container .tab-bar{
    flex-direction: column;
  }
 

  /* Carousel */
  .carousel-banner{
    flex-direction: column !important;
    height: 100%  !important;
    justify-content: center;
  }
  .carousel-banner div{
    width: 70vw !important;
    margin: 0 auto;
  }
  .carousel-caption h2{
    font-size: 1rem !important;
  }
 
  /* Project Page */  
  .pj-container, .pj-big{
    width: 90vw !important;
  }
  .pj-big h2{
    font-size: 1.2rem !important;
  }  
  .pj-big p{
    font-size: 1rem !important;
  }
  .proj-card{
    width: 40vw !important;
  }
  .pj-container .top img{
    width: 40vw !important;
  }

}


@media only screen and (max-width: 760px){  
  /* home title */
  .home-banner h1{font-size: 3.25rem !important}
  .home-banner h3{font-size: 1.25rem !important}
  .home-banner{height: 100%}

  /* banner 1 - about */
  .banner-1 div{
    width: 48% !important;
  }


  /* Carousel */
  
  .carousel-banner div{
    width: 90vw !important;
    margin: 0 auto;
  }
  .carousel-caption{
    height: 100% !important;
  }
  .carousel-caption h2{
    font-size: 1.5rem !important;
    margin: 15px 0 30px 0 ;
  }
 
}

@media only screen and (max-width: 700px){  
  /* Banner 2 - Tech Skills */  
  .tech-stack{
    flex-direction: column !important;
  }
  .tech-stack div{
    width: 95vw !important;
  }
  /* Project Page */  
  .pj-big p{
    font-size: 14px !important;
  }
  .proj-card h2{
    display: none;
  }
  .proj-card {
    width: 20vw !important;
    height: 15vw !important;
  }
  .pj-container .top img{
    width: 20vw !important;
    height: 15vw !important;
    object-fit: cover !important;
  }

}

@media only screen and (max-width: 565px){
  /*  Banner 3 - Video Intro */
  .video-container{
    flex-direction: column-reverse;
  }
  .video-container .question-container {
    width: 90% !important;
    justify-content: center;
    text-align: left !important;
  }
  .video-container .tab-bar{
    flex-direction: row;
    justify-content: flex-start;
    text-align: left !important;
  }
}

/* CELL PHONES */
@media only screen and (max-width: 430px){

  
  /* home title */
  .home-banner h1{font-size: 3.5rem !important}
  .home-banner h3{font-size: 1.5rem !important}
  
  
  /* Banner 1 - About */
  .banner-1{
    flex-direction: column;
  }
  .banner-1 div{
    width: 95% !important;
  }
  .pic-title{
    width: 90% !important; 
    margin: auto;
  }
  .pic-title img{
    height: 30vh !important;
    width: 50vw !important;
    border-radius: 50%;
  }
  

  /* Projects Page */
  .pj-big{
    flex-direction: column !important;
    height: 100% !important;
    justify-content: center !important;
    padding: 0 !important;
  }
  .pj-big img{
    width: 90vw !important;
    height: 20vh !important;
  }
  .pj-big div{
    width: 95% !important;
    padding: 5px 0;
  }
  .pj-big h2{
    text-align: center !important;
  }
  .pj-container{
    flex-direction: column !important;
  }
  .proj-card{
    margin: 0 auto;
    width: 90vw !important;
    height: 100% !important;
    padding: 0 auto !important;
    display: flex;
    flex-direction: row !important;
    justify-content: flex-start !important;
  }
  .pj-container span{
    padding: 0 !important;
  }
  .proj-card .top img{
    width: 30vw !important;
    height: 10vh !important;
  }
  .proj-card h2{
    display: initial;
    font-size: 1rem !important;
    padding-left: 10px !important;
  }

  /* Banner 3 - Video Intro */
  .video-container video{
    height: 80vh;
  }
  .video-container .question-container{
    display: none;
  }
  .video-container .meet-sandy{
    display: initial !important;
    font-size: 2rem;
  }

}



